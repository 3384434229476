// Define classes for active and passive steps
const stepClasses = {
    active: 'stepper-step stepper-active',
    passive: 'stepper-step'
};

// Define the steps for the stepper
const steeps = [
    { title: 'Heading', className: stepClasses.passive, path: 'heading' },
    { title: 'Work History', className: stepClasses.passive, path: 'work' },
    { title: 'Education', className: stepClasses.passive, path: 'education' },
    { title: 'Skills', className: stepClasses.passive, path: 'skills' },
    { title: 'Summary', className: stepClasses.passive, path: 'summary' },
    { title: 'Finalize', className: stepClasses.passive, path: 'finalize' }
];

export { steeps, stepClasses };
