import Header from "../components/header";
import Footer from "../components/footer";
import {Outlet} from "react-router-dom";
import {AuthContext} from "../context/context";
import {useContext} from "react";
import Login from "../firbase/login";

const Layout = () => {
    const {currentUser} = useContext(AuthContext);
    console.log("Current user",currentUser);
    if(!currentUser) {
         return <Login/>
    }
    return (
        <div className="layout">
            <Header/>
            <Outlet/>
            <Footer/>
        </div>
    );
};

export default Layout;