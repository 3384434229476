import {Input} from "antd";

function CheckBoxInput(props) {
    return <div className="form-check">
        <Input
            className="form-check-input appearance-none
                                        border border-gray-300 rounded-sm bg-white checked:bg-blue-600
                                        checked:border-blue-600 focus:outline-none transition duration-200
                                        mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2
                                        cursor-pointer m-4"
            type="checkbox" value="" id={props.id}/>
        <label className="form-check-label inline-block text-gray-800 text-xl ml-4"
               htmlFor={props.id}>
            {props.label}
        </label>
    </div>;
}

export default CheckBoxInput;