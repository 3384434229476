const Strength = () => {
    return (<div className="grid grid-cols-6 text-blue-500 text-base text-clip font-semibold">
        <div className="col-span1 leading-loose pt-0.5">
            Strength
        </div>
        <div className="col-span1">
            <div className="stepper-head">
           <span className="stepper-head-icon border border-solid
            border-gray-300 p-2 w-10 h-10 m-0 bg-blue-500">100%</span>
            </div>
        </div>
    </div>);
}
export default Strength;