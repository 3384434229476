import {Logo} from "./header";

function HeaderLess() {
    return (
        <header className="sticky top-0 z-30 w-full px-2 py-4 bg-white sm:px-4 shadow-xl">
            <div className="flex items-center justify-between mx-auto max-w-7xl">
                {Logo}
            </div>
        </header>
    );
}

export default HeaderLess;