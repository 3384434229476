import React, {useEffect} from "react";

const Notification = ({ message, onClose }) => {
    // Set up the auto-close functionality
    useEffect(() => {
        // Set a timer for 5 seconds
        const timer = setTimeout(() => {
            onClose();
        }, 5000);

        // Clean up the timer if the component unmounts
        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div style={styles.notificationContainer}>
            <div style={styles.notificationMessage}>
                {message}
            </div>
            <button style={styles.closeButton} onClick={onClose}>
                Close
            </button>
        </div>
    );
};

// You can define the styles here
const styles = {
    notificationContainer: {
        backgroundColor: '#50984c', // Green background
        color: 'white', // White text
        padding: '10px',
        position: 'fixed', // Fixed at the top
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000, // Ensure it's above other elements
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    },
    notificationMessage: {
        fontSize: '14px',
    },
    closeButton: {
        backgroundColor: 'transparent',
        border: 'none',
        color: 'white',
        cursor: 'pointer',
        padding: '5px 10px',
        marginLeft: '10px',
    }
};

export default Notification;

