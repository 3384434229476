import {Route, Routes} from "react-router-dom";
import React from "react";
import Wizard from "./app/wizard/wizard";
import Heading from "./app/wizard/heading";
import Work from "./app/wizard/work";
import Education from "./app/wizard/education";
import Skills from "./app/wizard/skills";
import Summary from "./app/wizard/summary";
import Finalize from "./app/wizard/finalize";
import FinalResume from "./app/wizard/final.resume";
import ChatContainer from "./app/messages/Chat.container";
import Resumes from "./app/resume/resumes";
import ExperienceLevel from "./app/wizard/experience-level";
import Templates from "./app/wizard/templates";
import Steeper from "./app/wizard/steeper";
import Dashboard from "./app/dashboard/dashboard";
import Resume from "./app/resume/resume";
import Login from "./firbase/login";
import Signup from "./firbase/signup";
import {AuthProvider} from "./providers/Auth";
import Layout from "./layout/Layout";
import Profile from "./app/resume/profile";
import "./App.css";

function App() {
    return (
        <AuthProvider>
            <Routes>
                <Route exact path="/login" element={<Login/>} />
                <Route exact path="/signup" element={<Signup/>} />
                <Route exact path="/" element={<Layout/>}>
                    <Route exact path="/" element={<Resumes/>} />
                    <Route exact path="/resumes" element={<Resumes/>} />
                    <Route exact path="/messages" element={<ChatContainer/>} />
                    <Route exact path="/dash" element={<Dashboard/>} />
                    <Route exact path="/resume" element={<Resume/>} />
                    <Route exact path="/wizard" element={<Wizard/>}>
                        <Route exact path="/wizard/" element={<Steeper/>}>
                            <Route exact path="/wizard/" element={<Heading/>} />
                            <Route exact path="/wizard/heading" element={<Heading/>} />
                            <Route exact path="/wizard/work" element={<Work/>} />
                            <Route exact path="/wizard/education" element={<Education/>} />
                            <Route exact path="/wizard/skills" element={<Skills/>} />
                            <Route exact path="/wizard/summary" element={<Summary/>} />
                            <Route exact path="/wizard/finalize" element={<Finalize/>} />
                        </Route>
                        <Route exact path="/wizard/final" element={<FinalResume/>} />
                        <Route exact path="/wizard/level" element={<ExperienceLevel/>} />
                        <Route exact path="/wizard/templates" element={<Templates/>} />
                    </Route>
                    <Route exact path="/profile" element={<Profile/>} />
                </Route>
            </Routes>
        </AuthProvider>
    );
}

export default App;