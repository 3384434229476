import React from 'react';
import {createRoot} from 'react-dom/client';
import {applyMiddleware, createStore} from "redux";
import createSagaMiddleware from "redux-saga";
import {Provider} from "react-redux";
import rootReducer from "./reducers";
import {logger} from "redux-logger";
import reportWebVitals from './reportWebVitals';
import './index.css';
import 'tw-elements';
import {BrowserRouter} from 'react-router-dom';
import watcherSaga from "./sagas/index";
import "antd/dist/antd.js";
import "antd/dist/reset.css";
import App from "./App";
import ErrorBoundary from "./utils/error.boundary";

const sagaMiddleware = createSagaMiddleware();
let store = createStore(rootReducer, applyMiddleware(sagaMiddleware, logger));
sagaMiddleware.run(watcherSaga);
const container = document.getElementById('root');
const root = createRoot(container);

const renderApp = () => {
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <ErrorBoundary>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </ErrorBoundary>
            </Provider>
        </React.StrictMode>
    );
};
renderApp();
reportWebVitals();
