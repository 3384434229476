import React, {useState} from "react";
import {Document, Page, pdfjs} from "react-pdf";

import '../app/wizard/wizard.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

PdfPreview.defaultProps = {
    width: 350,
    height: 430
}

export default function PdfPreview(props) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const {pdf} = props;

    function range(start, end) {
        var ans = [];
        for (let i = start; i <= end; i++) {
            ans.push(i);
        }
        return ans;
    }

    function getPagination(i) {

        return (<li className={'page-item ' + (pageNumber === i ? 'active' : '')} key={i}>
            <button onClick={() => setPageNumber(i)}
                    className={(pageNumber === i ? 'page-item-button-active' : 'page-item-button')}> {i}
                <span className="visually-hidden">(current)</span>
            </button>
        </li>)

    }

    return (
        <>
            <div className="border rounded w-fit ml-10">
                <Document
                    file={pdf}
                    loading={() => {
                        return (<>
                            <div className="flex justify-center items-center mt-12 ml-12">
                                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                                     role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </>)
                    }}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={(e) => console.log("erreur loading ", e)}>
                    <Page pageNumber={pageNumber} height={props.height} width={props.width}/>
                </Document>
            </div>
            <div className="grid grid-row-2 ">
                <p className="text-center m-2 text-gray-400">
                    Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                </p>
            </div>
            <div className="flex justify-center">
                <nav aria-label="Page navigation example">
                    <ul className="flex list-style-none">
                        <li className="page-item disabled">
                            <button disabled={pageNumber <= 1} onClick={() => previousPage()}
                                    className="page-item-button"
                                    tabIndex="-1" aria-disabled="true">Previous
                            </button>
                        </li>

                        {
                            range(1, numPages).map((i) => getPagination(i))
                        }
                        <li className="page-item">
                            <button disabled={pageNumber >= numPages} onClick={() => nextPage()}
                                    className="page-item-button">Next
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    );
}
