import "./london.css";

const London = () => {
    return (
        <>
            <div class="sheet">
                <button class="btn btn-print btn-sm btn-light" onClick="handlePrint()">
                    <i class="fa fa-print"></i>
                    Print
                </button>
                <div class="two-column resume">
                    <section class="resume__section resume__header">
                        <div class="resume__content">
                            <h1>Thiago Braga</h1>
                            <div class="info-item"><span class="info-label"><i
                                class="fa fa-location-arrow"></i></span><span class="info-text">
                770 Marçal de Arruda Campos St., Bauru, SP, Brazil,
                Zip: 17063-060</span></div>
                            <div class="info-item"><span class="info-label"><i class="fa fa-envelope"></i></span><span
                                class="info-text">contato@thiagobraga.org</span></div>
                            <div class="info-item"><span class="info-label"><i class="fa fa-phone"></i></span><span
                                class="info-text">+55 14 99165 5873</span></div>
                        </div>
                    </section>
                    <div class="resume__columns">
                        <div class="resume__main">
                            <section class="resume__section resume__summary">
                                <div class="resume__content">
                                    <div class="resume__section-title"><i class="fa fa-pencil-square-o"></i>
                                        <h2>Professional Summary</h2>
                                    </div>
                                    <div class="other">
                                        <div class="other-info">
                                            <p>
                                                PHP & JavaScript developer + Devops Enthusiast with a
                                                decade of success leading teams in delivering appropriate
                                                technology solutions for desktop and mobile products.
                                            </p>
                                            <p>
                                                Comprehensive knowledge of enterprise architecture,
                                                agile methodologies, remote work, cloud services and
                                                web-based applications.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="resume__section resume__experience">
                                <div class="resume__content">
                                    <div class="resume__section-title"><i class="fa fa-briefcase"></i>
                                        <h2>Employment History</h2>
                                    </div>
                                    <div class="xp-item">
                                        <div class="xp-job">
                                            Full Stack Developer / DevOps
                                            <span>@ Grupo Tesseract</span><br/><small>Bauru, Sao Paulo</small>
                                        </div>
                                        <div class="xp-date">Apr. 2017 – current</div>
                                        <div class="xp-detail">
                                            <ul>
                                                <li>
                                                    Design, build or maintain web sites using Laravel,
                                                    Bootstrap, Vue, React and WordPress
                                                </li>
                                                <li>Create scripting language tools</li>
                                                <li>Automate dev, builds and deploy tasks</li>
                                                <li>
                                                    Maintain understanding of current web technologies or
                                                    programming practices through continuing education,
                                                    reading and sharing knowledge
                                                </li>
                                                <li>
                                                    Develop databases that support web applications and
                                                    web sites
                                                </li>
                                                <li>
                                                    Develop and document style guidelines for web site
                                                    content
                                                </li>
                                                <li>Recommend and implement performance improvements</li>
                                                <li>
                                                    Select programming languages, design tools,
                                                    or applications
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="xp-item">
                                        <div class="xp-job">
                                            Full Stack Developer
                                            <span>@ Jurid Publicações Eletrônicas</span><br/><small>Bauru, Sao
                                            Paulo</small>
                                        </div>
                                        <div class="xp-date">Aug. 2018 – Apr. 2020</div>
                                        <div class="xp-detail">
                                            <ul>
                                                <li>
                                                    Build or maintain web sites using native PHP, Python
                                                    and JavaScript
                                                </li>
                                                <li>
                                                    Maintain and improve production databases running on
                                                    Elasticsearch, Redis, PostgreSQL and MySQL
                                                </li>
                                                <li>Provide backup and maintenance of GNU/Linux servers</li>
                                                <li>Provide documentation for existent and new applications</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="resume__side">
                            <section class="resume__section resume__skills">
                                <div class="resume__content">
                                    <div class="resume__section-title"><i class="fa fa-align-center"></i>
                                        <h2>Skills</h2>
                                    </div>
                                    <div class="resume__text">
                                        <div class="extra">
                                            <div class="extra-info">PHP<br/><small>PHP 5 · PHP 7 · Laravel</small></div>
                                            <div class="extra-details">
                                                <div class="extra-details__progress" style={{width: "90%"}}></div>
                                            </div>
                                        </div>
                                        <div class="extra">
                                            <div class="extra-info">JavaScript<br/><small>React · React Native ·
                                                Vue</small></div>
                                            <div class="extra-details">
                                                <div class="extra-details__progress" style={{width: "87%"}}></div>
                                            </div>
                                        </div>
                                        <div class="extra">
                                            <div class="extra-info">HTML<br/><small>HTML5 · Markdown · Pug</small></div>
                                            <div class="extra-details">
                                                <div class="extra-details__progress" style={{width: "100%"}}></div>
                                            </div>
                                        </div>
                                        <div class="extra">
                                            <div class="extra-info">CSS<br/><small>Stylus · Sass · Bootstrap</small>
                                            </div>
                                            <div class="extra-details">
                                                <div class="extra-details__progress" style={{width: "100%"}}></div>
                                            </div>
                                        </div>
                                        <div class="extra">
                                            <div class="extra-info">DevOps<br/><small>Docker · Shell · AWS ·
                                                CI/CD</small></div>
                                            <div class="extra-details">
                                                <div class="extra-details__progress" style={{width: "82%"}}></div>
                                            </div>
                                        </div>
                                        <div class="extra">
                                            <div class="extra-info">Databases<br/><small>PostgreSQL · MySQL ·
                                                Elasticsearch · Redis</small></div>
                                            <div class="extra-details">
                                                <div class="extra-details__progress" style={{width: "80%"}}></div>
                                            </div>
                                        </div>
                                        <div class="extra">
                                            <div class="extra-info">Operating Systems<br/><small>
                                                <i class="fa fa-linux"></i> GNU/Linux ·
                                                <i class="fa fa-apple"></i> Mac OS ·
                                                <i class="fa fa-windows"></i> Windows</small></div>
                                            <div class="extra-details">
                                                <div class="extra-details__progress" style={{width: "90%"}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="resume__section resume__languages">
                                <div class="resume__content">
                                    <div class="resume__section-title"><i class="fa fa-globe"></i>
                                        <h2>Languages</h2>
                                    </div>
                                    <div class="extra">
                                        <div class="extra-info">Portuguese <small>(native)</small></div>
                                        <div class="extra-details">
                                            <div class="extra-details__progress" style={{width: "100%"}}></div>
                                        </div>
                                    </div>
                                    <div class="extra">
                                        <div class="extra-info">English</div>
                                        <div class="extra-details">
                                            <div class="extra-details__progress" style={{width: "65%"}}></div>
                                        </div>
                                    </div>
                                    <div class="extra">
                                        <div class="extra-info">Spanish</div>
                                        <div class="extra-details">
                                            <div class="extra-details__progress" style={{width: "20%"}}></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </>);
}
export default London;
    
    