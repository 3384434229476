import React, { forwardRef } from "react";
import PageButtons from "./page.buttons";
// Using forwardRef for more intuitive ref passing
const Preview = forwardRef(({ scale, templateSwitch }, ref) => {
    return (
        <div className="block rounded-lg shadow-lg mt-8 mr-8 bg-white">
            <div className="py-3 px-6 border-b border-gray-300">
                <span className="text-xl text-blue-600">Preview</span>
                <PageButtons />
            </div>
            <main className="flex justify-center items-center m-auto w-fit md:w-auto flex-col-reverse">
                <div className="m-auto w-fit md:h-fit">
                    <section
                        ref={ref}
                        className="bg-white md:rounded-md p-8 h-[840px] w-[524px] transition-all"
                        style={{ transform: `scale(${scale})` }}
                    >
                        {templateSwitch}
                    </section>
                </div>
            </main>
        </div>
    );
});

export default Preview;
