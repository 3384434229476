import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import HeaderLess from "../../components/header-less";

const ExperienceLevel = () => {

    const [level, setLevel] = useState();

    const navigate = useNavigate();

    const handleLevel = (level) => {
        console.log('level : ', level);
        setLevel(level);

    }

    useEffect(() => {
        if (level) navigate('/wizard/templates', {state: level})
    }, [level, navigate])

    return (
        <>
            <HeaderLess></HeaderLess>
            <div className="grid grid-cols-12 grid-rows-3 grid-flow-row max-h-screen overflow-y-clip">
                <div className="col-span-6 col-start-5 row-start-2">
                    <p className="text-4xl text-blue-700 mb-4">How long have you been working?</p>
                    <p className="text-2xl text-gray-700 mb-20">We'll find the best templates for your experience
                        level.</p>
                </div>
                <div className="col-span-8 row-start-3 col-start-3">
                    <ul className="flex flex-row font-serif font-semibold text-gray-500">
                        <li className="border border-solid hover:bg-orange-50 cursor-pointer active:bg-orange-200 border-blue-400 w-56 h-16 text-center pt-5 mr-10"
                            onClick={() => handleLevel('no-experience')}>
                            No Experience
                        </li>
                        <li className="border border-solid hover:bg-orange-50 cursor-pointer active:bg-orange-200
                         border-blue-400 w-56 h-16 text-center pt-5 mr-10"
                            onClick={() => handleLevel('less-one-year')}>
                            Less Than Three Years
                        </li>
                        <li className="border border-solid hover:bg-orange-50 cursor-pointer active:bg-orange-200
                         border-blue-400 w-56 h-16 text-center pt-5 mr-10"
                            onClick={() => handleLevel('3-5-year')}>
                            3-5 Years
                        </li>
                        <li className="border border-solid hover:bg-orange-50 cursor-pointer active:bg-orange-200
                         border-blue-400 w-56 h-16 text-center pt-5 mr-10"
                            onClick={() => handleLevel('5-10-year')}>
                            5-10 Years
                        </li>
                        <li className="border border-solid hover:bg-orange-50 cursor-pointer active:bg-orange-200
                         border-blue-400 w-56 h-16 text-center pt-5 mr-10"
                            onClick={() => handleLevel('plus-10-year')}>
                            +10 Years
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default ExperienceLevel;