import {Outlet} from "react-router-dom";
import {WizardContext} from "../../context/context";
import {useState} from "react";
import {emptyCv} from "../../data/cvData";

function Wizard() {

    const [wizardState, setWizardState] = useState('');

    const [newResume, setNewResume] = useState(emptyCv);


    return (
        <WizardContext.Provider value={
            {
                newResume,
                setNewResume,
                wizardState,
                setWizardState
            }
        }>
            <Outlet/>
        </WizardContext.Provider>
    );
}

export default Wizard;