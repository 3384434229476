import {useEffect, useRef, useState} from "react";
import {useReactToPrint} from "react-to-print";
import {FILE_NOT_SELECTED, FILE_READ_ERROR, UNSUPPORTED_FILE_TYPE} from "../../constants/messages.constants";
import Settings from "./settings";
import Preview from "./Preview";
import {CvContext} from "../../context/context";
import {CV, CV2, CV3} from "../../templates/cv.templates";
import Card from "../../components/card";
import {cvData} from "../../data/cvData";
import LocalStorage from "../../utils/browser.utils";

function Profile() {
    const [cv, setCv] = useState(cvData);
    const [scale, setScale] = useState(1);
    const [template, setTemplate] = useState(1);
    const componentRef = useRef();

    // Initialize or update CV data from local storage
    useEffect(() => {
        const cvLocal = LocalStorage.get("cv");
        if (cvLocal) setCv({...cv, ...cvLocal});
    }, [cv]);

    // Update CV data in both state and local storage
    const updateCvData = (key, value) => {
        const updatedCv = {...cv, [key]: value};
        setCv(updatedCv);
        LocalStorage.set({key: "cv", payload: updatedCv});
    };

    // Handle CV template switching
    const renderTemplate = () => {
        const templates = {1: <CV2/>, 2: <CV3/>, 3: <CV/>};
        return templates[template] || <CV2/>;
    };

    // Handle image upload and validation
    const uploadImage = (e) => {
        const file = e.target.files[0];
        if (!file) throw new Error(FILE_NOT_SELECTED);

        const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];
        if (!allowedTypes.includes(file.type)) throw new Error(UNSUPPORTED_FILE_TYPE);

        const reader = new FileReader();
        reader.onload = (event) => updateCvData("image", event.target.result);
        reader.onerror = () => {
            throw new Error(FILE_READ_ERROR);
        }
        reader.readAsDataURL(file);
    };

    // Print the CV
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: cv.name,
        onAfterPrint: () => console.log("Printed"),
    });

    // Adjust the scale and print
    const adjustScaleAndPrint = () => {
        if (scale !== 1) setScale(1);
        setTimeout(() => handlePrint(), 100);
    };

    return (
        <CvContext.Provider value={{...cv, updateCvData, uploadImage, setTemplate}}>
            <div className="grid grid-cols-12 gap-2">
                <div className="col-span-2">
                    <Card/>
                </div>
                <div className="col-span-6">
                    <main className="settings">
                        <Settings/>
                    </main>
                </div>
                <div className="col-span-4">
                    <Preview ref={componentRef} scale={scale} template={renderTemplate()} templateSwitch={""}/>
                </div>{/* Print Button */}
                <button onClick={adjustScaleAndPrint} className="print-button">
                    Print CV
                </button>
            </div>
            <div className="print-container">
                <div className="print-button-container">
                    <button onClick={adjustScaleAndPrint} className="print-button">
                        Print CV
                    </button>
                </div>
            </div>
        </CvContext.Provider>
    );
}

export default Profile;
