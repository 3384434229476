import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';


const AutoRedirect = ({ to, delay }) => {
    const  navigate  = useNavigate();
    useEffect(() => {
        const timer = setTimeout(() => {
            navigate(to);
        }, delay);

        // Clean up the timer if the component unmounts
        return () => clearTimeout(timer);
    }, [to, delay, navigate]);

    return (
        <div>
            Redirecting in {delay / 1000} seconds...
        </div>
    );
};

export default AutoRedirect;
