import {
    faBarsStaggered,
    faDownload,
    faGraduationCap,
    faHeading,
    faListCheck,
    faNetworkWired,
    faPlus,
    faSpellCheck
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function CheckMenu() {
    return (
        <>
            <div className="w-60 h-full shadow-md bg-white relative">
                <ul className="relative px-1">
                    <li className="relative">
                        <h1 className="flex items-center text-sm py-4 px-6 h-12 overflow-hidden
                         text-gray-700 text-ellipsis whitespace-nowrap rounded
                          hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out">

                            <span className="font-serif font-semibold">Resume Check</span>
                        </h1>
                    </li>
                    <li className="relative border-b">
                        <a className="flex items-center text-sm py-4 px-6 h-12 overflow-hidden
                         text-gray-700 text-ellipsis whitespace-nowrap rounded
                          hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                           href="#!" data-mdb-ripple="true" data-mdb-ripple-color="primary">
                            <FontAwesomeIcon icon={faSpellCheck}></FontAwesomeIcon>
                            <span className="font-mono font-medium ml-2">Spell Check</span>
                        </a>
                    </li>
                </ul>
                <ul className="relative px-1">
                    <li className="relative">
                        <h1 className="flex items-center text-sm py-4 px-6 h-12 overflow-hidden
                         text-gray-700 text-ellipsis whitespace-nowrap rounded
                          hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out">
                            <span className="font-serif font-semibold">Resume Sections</span>
                        </h1>
                    </li>
                    <li className="relative">
                        <a className="flex items-center text-sm py-4 px-6 h-12 overflow-hidden
                         text-gray-700 text-ellipsis whitespace-nowrap rounded
                          hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                           href="#!" data-mdb-ripple="true" data-mdb-ripple-color="primary">
                            <FontAwesomeIcon icon={faHeading}></FontAwesomeIcon>
                            <span className="font-mono font-medium ml-2">Heading</span>
                        </a>
                    </li>
                    <li className="relative">
                        <a className="flex items-center text-sm py-4 px-6 h-12 overflow-hidden
                         text-gray-700 text-ellipsis whitespace-nowrap rounded
                          hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                           href="#!" data-mdb-ripple="true" data-mdb-ripple-color="primary">
                            <FontAwesomeIcon icon={faBarsStaggered}></FontAwesomeIcon>
                            <span className="font-mono font-medium ml-2">Summary</span>
                        </a>
                    </li>
                    <li className="relative">
                        <a className="flex items-center text-sm py-4 px-6 h-12 overflow-hidden
                         text-gray-700 text-ellipsis whitespace-nowrap rounded
                          hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                           href="#!" data-mdb-ripple="true" data-mdb-ripple-color="primary">
                            <FontAwesomeIcon icon={faNetworkWired}></FontAwesomeIcon>
                            <span className="font-mono font-medium ml-2">Work History</span>
                        </a>
                    </li>
                    <li className="relative">
                        <a className="flex items-center text-sm py-4 px-6 h-12 overflow-hidden
                         text-gray-700 text-ellipsis whitespace-nowrap rounded
                          hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                           href="#!" data-mdb-ripple="true" data-mdb-ripple-color="primary">
                            <FontAwesomeIcon icon={faListCheck}></FontAwesomeIcon>
                            <span className="font-mono font-medium ml-2">Skills</span>
                        </a>
                    </li>
                    <li className="relative border-b">
                        <a className="flex items-center text-sm py-4 px-6 h-12 overflow-hidden
                         text-gray-700 text-ellipsis whitespace-nowrap rounded
                          hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                           href="#!" data-mdb-ripple="true" data-mdb-ripple-color="primary">
                            <FontAwesomeIcon icon={faGraduationCap}></FontAwesomeIcon>
                            <span className="font-mono font-medium ml-2">Education</span>
                        </a>
                    </li>
                    <li className="relative border-b">
                        <a className="flex items-center text-sm py-4 px-6 h-12 overflow-hidden
                         text-gray-700 text-ellipsis whitespace-nowrap rounded
                          hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                           href="#!" data-mdb-ripple="true" data-mdb-ripple-color="primary">
                            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                            <span className="font-mono font-medium ml-2">Add Section</span>
                        </a>
                    </li>
                    <li className="relative border-b">
                        <a className="flex items-center text-sm py-4 px-6 h-12 overflow-hidden
                         text-gray-700 text-ellipsis whitespace-nowrap rounded
                          hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                           href="#!" data-mdb-ripple="true" data-mdb-ripple-color="primary">
                            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                            <span className="font-mono font-medium ml-2">Download</span>
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default CheckMenu;
