import {BsZoomIn, BsZoomOut} from "react-icons/bs";
import {GoDownload} from "react-icons/go";
import {useContext} from "react";
import {CvContext} from "../../context/context";

const CvControlButtons = () => {
    const { scaleUp, scaleDown, handleDownload } = useContext(CvContext);

    return (
        <div
            className="flex items-center justify-center space-x-5 px-5 py-3 border border-black/10 bg-white/50 backdrop-blur-2xl rounded-full transition-all text-neutral-500 z-10 w-fit mx-auto">
            <button
                className="buttonHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={scaleUp} aria-label="Zoom in">
                <BsZoomIn className="h-8 w-8"/>
            </button>
            <button
                className="buttonHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={scaleDown} aria-label="Zoom out">
                <BsZoomOut className="h-8 w-8"/>
            </button>
            <button
                className="buttonHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={handleDownload} aria-label="Download CV">
                <GoDownload className="h-8 w-8"/>
            </button>
        </div>
    );
};

export default CvControlButtons;
