import axios from "axios";
import React, {useEffect, useState} from "react";

const Dashboard = () => {
    const [resumes, setResumes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const allResumes = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get("http://localhost:8085/resume/all");
                setResumes(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setError(error);
                setIsLoading(false);
            }
        };

        allResumes();
    }, []);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div>
            {resumes.map(resume => (
                <div key={resume.id}>{resume.owner}</div>
            ))}
        </div>
    );
};

export default Dashboard;
