import {useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {SteepContext} from "../../context/context";
import Buttons from "./buttons";
import SteeperHeader from "./steeper.header";
import {steeps as initialSteeps, stepClasses} from "./steeps";

const Steeper = () => {
    const [currentSteep, setCurrentSteep] = useState(0);
    const [steeps, setSteeps] = useState(() =>
        initialSteeps.map(steep => ({
            ...steep,
            className: stepClasses.passive
        }))
    );
    useNavigate();
    const location = useLocation();

    useEffect(() => {
        console.log("Location:", location);

        const path = location.pathname;

        console.log("Path:", path);

        const newSteeps = steeps.map((steep) => ({
            ...steep,
            className: path.includes(steep.path) ? stepClasses.active : stepClasses.passive
        }));

        setSteeps(newSteeps);

        const currentSteepIndex = newSteeps.findIndex(steep => path.includes(steep.path));
        if (currentSteepIndex !== -1) {
            setCurrentSteep(currentSteepIndex);
        }
    }, [location, steeps]);
    console.log("Current Steep:", currentSteep);
    console.log("Steeps:", steeps);

    return (
        <SteepContext.Provider value={{steeps, currentSteep, setCurrentSteep}}>
            <nav className="navbar">
                <div className="px-6 w-full">
                    <SteeperHeader steeps={steeps} />
                </div>
            </nav>
            <Outlet />
            <Buttons
                back={currentSteep > 0 ? steeps[currentSteep - 1].title : ''}
                next={currentSteep < steeps.length - 1 ? steeps[currentSteep + 1].title : ''}
            />
        </SteepContext.Provider>
    );
}

export default Steeper;
