import {useContext, useState} from "react";
import {WizardContext} from "../../context/context";
import TextInput from "../../components/basic/text.input";
import DatePicker from "../../components/basic/date.picker";
import PdfPreview from "../../components/pdf.preview"
import CheckBoxInput from "../../components/basic/check.box.input"

function Education() {
    const {newResume, setNewResume} = useContext(WizardContext);

    const [lastEducation, setLastEducation] = useState(newResume.education[0]);

    const handleChange = (e) => {
        setLastEducation({...lastEducation, [e.target.id]: e.target.value})
        let educations = newResume.education;
        educations[0] = lastEducation;
        setNewResume({...newResume, education: educations});
        console.log("change in education : ", lastEducation);
        console.log("A New Resume Updated: ", newResume);
    }
    return (
        <>
            <div className="grid grid-cols-12">
                <div className="col-span-8 flex flex-grow flex-col my-16 ml-[150px] mr-[50px]">
                    <p className="text-4xl text-blue-700 mb-4">Tell us about your education</p>
                    <p className="text-2xl text-gray-700 mb-20">Include every school, even if you're still there or
                        didn't graduate.</p>
                    <div className="block p-6 rounded-lg shadow-lg bg-white max-w-5xl">
                        <form>
                            <div className="grid grid-cols-2 gap-4">
                                <TextInput label="School Name" value={lastEducation.school} onChange={handleChange}
                                           id="school"
                                           placeholder="ex: Princeton University"/>
                                <TextInput label="School Location" value={lastEducation.location}
                                           onChange={handleChange} id="location"
                                           placeholder="ex: New York, US"/>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <TextInput label="Degree" value={lastEducation.degree} onChange={handleChange}
                                           id="degree"
                                           placeholder="ex: Master of Science"/>
                                <TextInput label="Field of Study" value={lastEducation.study} onChange={handleChange}
                                           id="study"
                                           placeholder="ex: Statistics"/>
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <DatePicker label="Start Date" value={lastEducation.startDate} onChange={handleChange}
                                            id="startDate" placeholder="ex: 12/12/2021"/>
                                <DatePicker label="End Date" value={lastEducation.endDate} onChange={handleChange}
                                            id="endDate" placeholder="ex: 12/12/2022"/>
                            </div>
                            <CheckBoxInput id="attend" label="I currently attend here"/>
                        </form>
                    </div>
                </div>
                <div className="col-span-4 m-16 max-h-[450px] overflow-hidden">
                    <PdfPreview pdf="http://localhost:3000/test.pdf"></PdfPreview>
                </div>
            </div>
        </>
    );
}

export default Education;