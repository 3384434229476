import axios from "axios";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Actions from "./actions";
import Strength from "./Strength";

const Resumes = () => {

    const navigate = useNavigate();

    const [resumes, setResumes] = useState([]);

    async function allResumes() {

        try {
            const response = await axios.get("http://localhost:8085/resume/all");
            setResumes(response.data);
        } catch (error) {
            console.error(error);
        }

    }

    useEffect(() => {
        allResumes();
    }, [resumes])


    const handleNewResume = () => {
        navigate('/wizard/level');
    }
    return (
        <>
            <div className="grid-flow-row content-center  mx-32 py-5 gap-10">
                <div className="grid grid-cols-8 grid-flow-row">
                    <div className="col-span-8">
                        <h1 className="text-4xl text-blue-700 mb-5">My Resumes</h1>
                    </div>
                    <div className="col-start-6 col-span-3 row-span-1">
                        <button type="button" onClick={handleNewResume}
                                className="inline-block px-6 py-2.5 bg-blue-600 float-right
                            text-white font-medium text-xs leading-tight
                            uppercase shadow-md hover:bg-blue-700
                            hover:shadow-lg focus:bg-blue-700 focus:shadow-lg
                            focus:outline-none focus:ring-0 active:bg-blue-800
                            active:shadow-lg divansition duration-150 ease-in-out">Create New Resume
                        </button>
                    </div>
                    <div className="col-span-8 w-full border border-solid border-gray-200 mt-5"></div>
                </div>
                <div className="flex flex-col">
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="overflow-hidden">
                                <div className="grid grid-flow-row">
                                    <div className="grid grid-cols-3 min-w-full mb-5">
                                        <div itemScope="col" className="text-sm font-medium text-gray-900 px-6 py-4">
                                            Resume Name
                                        </div>
                                        <div itemScope="col" className="text-sm font-medium text-gray-900 px-6 py-4">
                                            Resume Strength
                                        </div>
                                        <div itemScope="col" className="text-sm font-medium text-gray-900 px-6 py-4">
                                            Actions
                                        </div>
                                    </div>
                                    {
                                        resumes.map((rec, index) => {
                                            return (
                                                <div className="border border-solid border-gray-300
                                                shadow-sm 
                                                grid grid-cols-3 min-w-full mb-5" key={index}>
                                                    <div
                                                        className="col-span-1 text-sm text-gray-500 font-medium px-6 py-4 whitespace-nowrap">
                                                        {rec.name}
                                                    </div>
                                                    <div
                                                        className="col-span-1 text-sm text-gray-500 font-light px-6 py-4 whitespace-nowrap">
                                                        <Strength/>
                                                    </div>
                                                    <div
                                                        className="col-span-1 text-sm text-gray-500 font-light px-6 py-4 whitespace-nowrap">
                                                        <Actions resume={rec} resumes={resumes}
                                                                 setResumes={setResumes}/>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Resumes;