import { Outlet } from "react-router-dom";
import { WizardContext } from "../../context/context";
import { useState } from "react";
import { emptyCv } from "../../data/cvData";

function Wizard() {
    // State for the current status or step in the wizard
    const [wizardState, setWizardState] = useState('');

    // State for storing the new resume data
    const [newResume, setNewResume] = useState(emptyCv);

    return (
        <WizardContext.Provider value={
            {
                newResume, // The current resume data
                setNewResume, // Function to update the resume data
                wizardState, // Current state of the wizard
                setWizardState // Function to update the wizard state
            }
        }>
            <Outlet/> {/* Renders the current route's component */}
        </WizardContext.Provider>
    );
}

export default Wizard;
