import {useNavigate} from "react-router-dom";
import HeaderLess from "../../components/header-less";
import Template from "./template";
import {Templates as data} from '../../data/templates';
import {useState} from "react";
import {TemplateContext} from "../../context/context";

const Templates = () => {

    const [templateState, setTemplateState] = useState(data);
    const navigate = useNavigate();

    const handleNavigation = (destination) => {
        navigate('/wizard', { state: destination });
    };

    return (
        <TemplateContext.Provider value={{templateState, setTemplateState}}>
            <>
                <HeaderLess></HeaderLess>
                <div className="grid grid-cols-12 grid-rows-3 grid-flow-row max-h-fit">
                    <div className="col-span-6 col-start-5 row-start-2">
                        <p className="text-4xl text-blue-700 mb-4">Here are the best templates for you</p>
                        <p className="text-2xl text-gray-700">They’re great for people with 3-5 years of experience.</p>
                    </div>
                    <div className="col-span-6 row-start-3 col-start-5">
                        <ul className="flex flex-row font-serif font-semibold text-gray-500">
                            <li className="border border-solid rounded
                     bg-slate-600 cursor-pointer hover:bg-slate-500
                     border-slate-600 w-10 h-10 text-center mr-7"/>
                            <li className="border border-solid rounded
                      bg-orange-600 cursor-pointer hover:bg-orange-500
                     border-orange-600 w-10 h-10 text-center mr-7"/>
                            <li className="border border-solid rounded
                      bg-red-600 cursor-pointer hover:bg-red-500
                     border-red-600 w-10 h-10 text-center mr-7"/>
                            <li className="border border-solid rounded
                      bg-green-700 cursor-pointer hover:bg-green-600
                     border-green-700 w-10 h-10 text-center mr-7"/>
                            <li className="border border-solid rounded
                      bg-gray-600 cursor-pointer hover:bg-gray-500
                     border-gray-600 w-10 h-10 text-center mr-7"/>
                            <li className="border border-solid rounded
                      bg-red-900 cursor-pointer hover:bg-red-800
                     border-red-900 w-10 h-10 text-center mr-7"/>
                            <li className="border border-solid rounded
                      bg-blue-900 cursor-pointer hover:bg-blue-800
                     border-blue-900 w-10 h-10 text-center mr-7"/>
                            <li className="border border-solid rounded
                      bg-amber-600 cursor-pointer hover:bg-amber-500
                     border-amber-600 w-10 h-10 text-center mr-7"/>
                            <li className="border border-solid rounded
                      bg-amber-900 cursor-pointer hover:bg-amber-800
                     border-amber-900 w-10 h-10 text-center mr-7"/>
                        </ul>
                    </div>
                    <div className="col-span-8 row-start-4 col-start-3">
                        <div className="grid grid-cols-3 gap-20">
                            {
                                templateState.map((rec, index) => {
                                    return (
                                        <div className="col-span1" key={index}>
                                            <Template id={rec.id} active={rec.active}
                                                      height={200} width={300} pdf="http://localhost:3000/test.pdf">
                                            </Template>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="flex flex-row fixed-bottom h-20 bg-neutral-100 border border-t-4">
                    <div className="grid sm:grid-cols-10 grid-flow-col w-full  gap-2 m-5 lg:grid-cols-7 lg:mr-14">
                        <div className="col-span-3 lg:col-start-5 flex flex-row">
                            <button type="button" onClick={() => handleNavigation('later')}
                                    className="inline-block px-6 py-2.5 bg-gray-600 min-w-[180px]
                                    text-white font-medium text-xs leading-tight
                                    uppercase shadow-md hover:bg-gray-700 mr-2
                                    hover:shadow-lg focus:bg-gray-700 focus:shadow-lg
                                    focus:outline-none focus:ring-0 active:bg-gray-800
                                    active:shadow-lg divansition duration-150 ease-in-out">Choose Later
                            </button>
                            <button type="button"  onClick={() => handleNavigation('choose')}
                                    className="inline-block px-6 py-2.5 bg-blue-600 min-w-[180px]
                                    text-white font-medium text-xs leading-tight
                                    uppercase shadow-md hover:bg-blue-700
                                    hover:shadow-lg focus:bg-blue-700 focus:shadow-lg
                                    focus:outline-none focus:ring-0 active:bg-blue-800
                                    active:shadow-lg divansition duration-150 ease-in-out">Choose Template
                            </button>
                        </div>
                    </div>
                </div>
            </>
        </TemplateContext.Provider>
    );
}

export default Templates;
