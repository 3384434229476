import CheckMenu from "../../components/check.menu"
import HeaderLess from "../../components/header-less";
import {Select, Slider} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown} from "@fortawesome/free-solid-svg-icons";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {useState} from "react";
import London from "../../templates/london";

const Modal = () => {

    return (

        <>
            <div
                className="modal animated fade ease-in duration-500 fixed bottom-0 mb-12 hidden w-full h-fit outline-none overflow-x-hidden overflow-y-auto"
                id="exampleModalFullscreen" tabIndex="-1" aria-labelledby="exampleModalFullscreenLabel"
                aria-hidden="true">
                <div className="modal-dialog max-w-full max-h-fit  relative w-full pointer-events-none">
                    <div
                        className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-clip-padding rounded-md outline-none text-current">
                        <div
                            className="modal-header flex flex-shrink-0 items-center justify-between p-4 bg-transparent">
                            <button type="button"
                                    className="btn-close animate-pulse animation hover:animate-bounce box-content w-4 h-4 p-1
                        text-black border-none rounded-none opacity-50 focus:shadow-none 
                        focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75
                        hover:no-underline"
                                    data-bs-dismiss="modal" aria-label="Close"/>

                        </div>
                        <div
                            className="modal-body border-y-2 border-gray-300 relative p-4 lg:min-h-[315px] md:min-h-[325px] bg-gray-200 opacity-100">
                            <div className="grid grid-cols-12 grid-flow-col gap-10 relative">
                                <div className="col-span-2  col-start-5 grid grid-cols-2">
                                    <h1 className="text-lg">Font Size</h1>
                                    <Slider styles={{track : {background: "blue"}}} style={{ handle:{ background: "black" }}}
                                            defaultValue={[10, 30]}></Slider>
                                </div>
                                <div className="col-span-2  col-start-7 grid grid-cols-2">
                                    <h1 className="text-lg">Line Spacing</h1>
                                    <Slider styles={{track : {background: "blue"}}} style={{ handle:{ background: "black" }}}
                                            defaultValue={[10, 30]}></Slider>
                                </div>
                                <div className="col-span-2  col-start-5 grid grid-cols-2">
                                    <h1 className="text-lg">Heading Size</h1>
                                    <Slider styles={{track : {background: "blue"}}} style={{ handle:{ background: "black" }}}
                                            defaultValue={[10, 30]}></Slider>
                                </div>
                                <div className="col-span-2  col-start-7 grid grid-cols-2">
                                    <h1 className="text-lg">Top & Bottom Margin Size</h1>
                                    <Slider styles={{track : {background: "blue"}}} style={{ handle:{ background: "black" }}}
                                            defaultValue={[10, 30]}></Slider>
                                </div>
                                <div className="col-span-2  col-start-5 grid grid-cols-2">
                                    <h1 className="text-lg">Side Margins</h1>
                                    <Slider styles={{track : {background: "blue"}}} style={{ handle:{ background: "black" }}}
                                            defaultValue={[10, 30]}></Slider>
                                </div>
                                <div className="col-span-2  col-start-7 grid grid-cols-2">
                                    <h1 className="text-lg">Section Spacing</h1>
                                    <Slider styles={{track : {background: "blue"}}} style={{ handle:{ background: "black" }}}
                                            defaultValue={[10, 30]}></Slider>
                                </div>
                                <div className="col-span-2  col-start-5 grid grid-cols-2">
                                    <h1 className="text-lg">Paragraph Indent Size</h1>
                                    <Slider styles={{track : {background: "blue"}}} style={{ handle:{ background: "black" }}}
                                            defaultValue={[10, 30]}></Slider>
                                </div>
                                <div className="col-span-2  col-start-7 grid grid-cols-2">
                                    <h1 className="text-lg">Paragraph Spacing</h1>
                                    <Slider styles={{track : {background: "blue"}}} style={{ handle:{ background: "black" }}}
                                            defaultValue={[10, 30]}></Slider>
                                </div>
                                <div className="col-span-2  col-start-7 grid grid-cols-2">
                                    <h1 className="text-lg">Line Weight</h1>
                                    <Slider styles={{track : {background: "blue"}}} style={{ handle:{ background: "black" }}}
                                            defaultValue={[10, 30]}></Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


function Resume() {

    const navigate = useNavigate();

    const {state} = useLocation();


    const [editedResume] = useState(state);

    const handleSave = () => {
        console.log("resume : ", editedResume);
        axios.put('http://localhost:8085/resume/update', editedResume)
            .then(function (response) {
                console.log(response);
                navigate("/resumes");
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <>
            <Modal></Modal>
            <HeaderLess></HeaderLess>
            <div className="fixed left-0 z-30 m-7 ml-20 w-64 top-30">
                <CheckMenu/>
            </div>
            <div className="grid grid-cols-12 gap-2 m-6">
                <div className="col-span-3"></div>
                <div className="col-span-6 justify-center mx-5">
                    <London resume={editedResume}></London>
                </div>
            </div>
            <div className="flex flex-row fixed-bottom h-20 bg-neutral-100 border border-t-4">
                <div className="grid sm:grid-cols-10 grid-flow-col w-full  gap-2 m-5 lg:grid-cols-7 lg:mr-14">
                    <div className="col-span-3 lg:col-start-5 flex flex-row gap-5">
                        <div className="cursor-pointer flex flex-row px-3 gap-2
                             border border-solid border-gray-300
                            bg-gray-300 text-orange-500"
                             data-bs-toggle="modal" data-bs-target="#exampleModalFullscreen">
                            <h1 className="leading-loose mb-0 w-44 text-orange-500">Formating</h1>
                            <h1 className="leading-loose mb-0 text-orange-500">Normal</h1>
                            <FontAwesomeIcon className="pt-2" icon={faArrowDown}></FontAwesomeIcon>
                        </div>
                        <Select className="inline-block px-6 py-2.5 bg-gray-600 min-w-[60px]
                                        text-white font-medium text-xs
                                        shadow-md hover:bg-gray-700 mr-2 after:mt-10
                                        hover:shadow-lg focus:bg-gray-700 focus:shadow-lg
                                        focus:outline-none focus:ring-0 active:bg-gray-800
                                        active:shadow-lg divansition duration-150 ease-in-out">

                            <Select.Option>
                                <div className="flex flex-row">
                                    <div className="border border-solid rounded
                        bg-slate-600 cursor-pointer hover:bg-slate-500
                        border-slate-600 w-5 h-5 text-center mr-3"/>
                                    <div> :Slate</div>
                                </div>
                            </Select.Option>
                            <Select.Option>
                                <div className="flex flex-row">
                                    <div className="border border-solid rounded
                            bg-orange-600 cursor-pointer hover:bg-orange-500
                            border-orange-600 w-5 h-5 text-center mr-3"/>
                                    <div> :Orange</div>
                                </div>
                            </Select.Option>
                            <Select.Option>
                                <div className="flex flex-row">
                                    <div className="border border-solid rounded
                            bg-red-600 cursor-pointer hover:bg-red-500
                            border-red-600 w-5 h-5 text-center mr-3"/>
                                    <div> :Red</div>
                                </div>
                            </Select.Option>
                            <Select.Option>
                                <div className="flex flex-row">
                                    <div className="border border-solid rounded
                            bg-green-700 cursor-pointer hover:bg-green-600
                            border-green-700 w-5 h-5 text-center mr-3"/>
                                    <div> :Green</div>
                                </div>
                            </Select.Option>
                            <Select.Option>
                                <div className="flex flex-row">
                                    <div className="border border-solid rounded
                            bg-gray-600 cursor-pointer hover:bg-gray-500
                            border-gray-600 w-5 h-5 text-center mr-3"/>
                                    <div> :Gray</div>
                                </div>
                            </Select.Option>
                            <Select.Option>
                                <div className="flex flex-row">
                                    <div className="border border-solid rounded
                            bg-red-900 cursor-pointer hover:bg-red-800
                            border-red-900 w-5 h-5 text-center mr-3"/>
                                    <div> :Brown</div>
                                </div>
                            </Select.Option>
                            <Select.Option>
                                <div className="flex flex-row">
                                    <div className="border border-solid rounded
                            bg-blue-900 cursor-pointer hover:bg-blue-800
                            border-blue-900 w-5 h-5 text-center mr-3"/>
                                    <div> :Blue</div>
                                </div>
                            </Select.Option>
                            <Select.Option>
                                <div className="flex flex-row">
                                    <div className="border border-solid rounded
                            bg-amber-600 cursor-pointer hover:bg-amber-500
                            border-amber-600 w-5 h-5 text-center mr-3"/>
                                    <div> :Orange</div>
                                </div>
                            </Select.Option>
                            <Select.Option>
                                <div className="flex flex-row">
                                    <div className="border border-solid rounded
                            bg-amber-900 cursor-pointer hover:bg-amber-800
                            border-amber-900 w-5 h-5 text-center mr-3"/>
                                    <div> :Brown</div>
                                </div>
                            </Select.Option>

                        </Select>
                        <button type="button" onClick={handleSave}
                                className="inline-block px-6 py-2.5 bg-blue-600 min-w-[180px]
                                        text-white font-medium text-xs leading-tight
                                        uppercase shadow-md hover:bg-blue-700
                                        hover:shadow-lg focus:bg-blue-700 focus:shadow-lg
                                        focus:outline-none focus:ring-0 active:bg-blue-800
                                        active:shadow-lg divansition duration-150 ease-in-out">Save & Next
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Resume;