function AreaInput(props) {


    return <div className="mb-3 xl:w-96">
        <label htmlFor={props.id} className="form-label inline-block mb-2 text-gray-700"
        >{props.label}</label
        >
        <textarea
            className="
            form-control
            block
            w-[700px]
            px-3
            py-1.5
            text-base
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
            "
            id={props.id}
            rows="15"
            value={props.value}
            onChange={(e) => props.onChange(e)}
            placeholder={props.placeholder}
        ></textarea>
    </div>;
}

export default AreaInput;