// CVTemplates.js

import React from 'react';
import CVTemplate from "./CV";
import CV3Template3 from "./CV3";
import CV2Template2 from "./CV2";

// Assuming each template has a unique layout and design
// These are placeholder components representing different CV layouts

export const CV = () => {
    return (
        <div className="cv-template">
            {/* CV template layout and content */}
            <h1>Template 1</h1>
            {/* Add more CV content and styling here */}
            <CVTemplate></CVTemplate>
        </div>
    );
};

export const CV2 = () => {
    return (
        <div className="cv-template">
            {/* CV template layout and content */}
            <h1>Template 2</h1>
            {/* Add more CV content and styling here */}
            <CV2Template2></CV2Template2>
        </div>
    );
};

export const CV3 = () => {
    return (
        <div className="cv-template">
            {/* CV template layout and content */}
            <h1>Template 3</h1>
            {/* Add more CV content and styling here */}
            <CV3Template3></CV3Template3>

        </div>
    );
};

// Add more templates as needed
