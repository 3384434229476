import { useContext, useRef, useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import { TemplateContext } from "../../context/context";

const Template = ({ id, pdf, height, width, active }) => {
    const { templateState, setTemplateState } = useContext(TemplateContext);
    const ref = useRef();
    const [, setNumPages] = useState(null);

    // Set class names based on the active state
    useEffect(() => {
        const className = active
            ? 'cursor-pointer border border-solid border-orange-400'
            : 'cursor-pointer hover:border hover:border-solid hover:border-blue-400';
        if (ref.current) {
            ref.current.className = className;
        }
    }, [active]);

    // Handles successful loading of the document
    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

    // Handle template click
    const handleClick = () => {
        setTemplateState(
            templateState.map(rec => ({
                ...rec,
                active: rec.id === id
            }))
        );
    };

    return (
        <div className="border rounded w-fit ml-10 overflow-hidden max-h-[450px]">
            <div ref={ref} id={id} onClick={handleClick}>
                <Document
                    file={pdf}
                    loading={<LoadingSpinner />}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={console.error}
                >
                    <Page pageNumber={1} height={height} width={width} scale={1} />
                </Document>
            </div>
        </div>
    );
};

const LoadingSpinner = () => (
    <div className="flex justify-center items-center mt-20 ml-20">
        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
);

export default Template;
