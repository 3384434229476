import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquarePlus} from "@fortawesome/free-solid-svg-icons/faSquarePlus";
import Ratings from "../../components/basic/ratings";
import TextInput from "../../components/basic/text.input";
import {useContext, useState} from "react";
import {WizardContext} from "../../context/context";


function Skills() {

    const {newResume, setNewResume} = useContext(WizardContext);

    const [skills, setSkills] = useState(newResume.skills || []);


    const handleChange = (e) => {
        const newSkills = skills.map(skl => {
            return ((skl.id === e.target.id) ? {id: e.target.id, value: e.target.value} : skl);
        });
        setSkills(newSkills);
        setNewResume({...newResume, skills: skills});
        console.log("change in skills : ", skills);
        console.log("A New Resume Updated: ", newResume);
    }

    const addSkill = () => {
        setSkills([...skills, {id: "skill-".concat(skills.length), value: ""}]);
        console.log(skills.length);
        console.log("skills add : ", skills);
    }

    return (<>
        <div className="flex flex-grow flex-col justify-center my-16 mx-[150px] min-h-full">
            <p className="text-4xl text-blue-700 mb-4">What skills would you like to highlight?</p>
            <div className="flex grid-cols-3 cardStyle lg:max-w-5xl">
                <button onClick={() => addSkill()}
                        className="flex cursor-pointer transition duration-300 ease active:bg-blue-300 w-fit">
                    <FontAwesomeIcon icon={faSquarePlus} className="mr-2 w-6 h-6 text-blue-700 hover:text-blue-400"/>
                    <p className="text-blue-500 font-semibold text-sm align-center w-max hover:text-blue-400">Add you
                        skills here</p>
                </button>
                <ul className="flex-block min-w-max" id="skills">
                    {skills.map((skill, index) => {
                        return <li className="grid grid-cols-5 gap-4 ex" key={index}>
                            <div className="pt-8 pl-12"><Ratings/></div>
                            <div className="col-span-4"><TextInput id={"skill-".concat(index)} onChange={handleChange}
                                                                   value={skill.value}/></div>
                        </li>;
                    })}
                </ul>
            </div>
        </div>

    </>);
}

export default Skills;