
import {combineReducers} from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import loginSlice from "../features/login/login.reducer";
import authReducer from "./Auth";
import chatReducer from "./Chat";

export const rootReducer = combineReducers({
    reducer: {
        counter: counterReducer,
        login: loginSlice,
        auth: authReducer,
        chat: chatReducer
    },
});

export default rootReducer;
