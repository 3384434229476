import {useContext, useState} from "react";
import {HiChevronRight} from "react-icons/hi";
import {AnimatePresence, motion} from "framer-motion";
import ExperienceEntry from "../../components/experienceEntry";
import {CvContext} from "../../context/context";
import AddButton from "../../components/motion/add.button";

function Experiences() {
    const {cv, addExperience} = useContext(CvContext);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <AnimatePresence>
            <motion.div
                layout
                className="cardStyle"
                animate={{marginBottom: isOpen ? "30px" : "20px"}}
            >
                <motion.div
                    initial={false}
                    onClick={() => setIsOpen(!isOpen)}
                    layout
                    className="w-full flex text-neutral-500 cursor-pointer text-lg"
                >
                    <span className="flex-1">Experiences</span>
                    <motion.div
                        className="inline-block items-end"
                        animate={{rotate: isOpen ? 90 : 0}}
                    >
                        <HiChevronRight className="inline w-6 h-6"/>
                    </motion.div>
                </motion.div>
                <AnimatePresence>
                    {isOpen && (
                        <motion.div
                            key="content"
                            initial="collapsed"
                            animate="open"
                            exit="collapsed"
                            variants={{
                                open: {opacity: 1, height: "auto"},
                                collapsed: {opacity: 0, height: 0},
                            }}
                            transition={{duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98]}}
                        >
                            {cv.experiences.map((experience, index) => (
                                <ExperienceEntry
                                    key={index}
                                    experience={experience}
                                    index={index}
                                />
                            ))}
                            <AddButton onClick={() => addExperience({
                                title: "",
                                company: "",
                                summary: "",
                                startDate: "",
                                endDate: "",
                            })}/>
                        </motion.div>
                    )}
                </AnimatePresence>
            </motion.div>
        </AnimatePresence>
    );
}

export default Experiences;
