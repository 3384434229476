import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from "firebase/auth";

export const firebaseConfig = {
    apiKey: "AIzaSyABMROIyXjsdP4YWiGNaecvMlH8wW0LWlo",
    authDomain: "exartresume.firebaseapp.com",
    projectId: "exartresume",
    storageBucket: "exartresume.appspot.com",
    messagingSenderId: "78520553002",
    appId: "1:78520553002:web:5d7d7d3df7709e0097bd53",
    measurementId: "G-ZBFE63EJ43"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
// initialize analytics and get a reference to the service
export const analytics = getAnalytics(firebaseApp);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(firebaseApp);

export default auth;