import {useContext} from "react";
import {WizardContext} from "../../context/context";
import MailInput from "../../components/basic/mail.input";
import TextInput from "../../components/basic/text.input";
import PdfPreview from "../../components/pdf.preview"

function Heading() {
    const {newResume, setNewResume} = useContext(WizardContext);

    const handleChange = (e) => {
        console.log("A value was changed: ", e.target.value);
        setNewResume({...newResume, [e.target.id]: e.target.value});
        console.log("A New Resume Updated: ", newResume);
    }

    return (
        <>
            <div className="grid grid-cols-12">
                <div className="col-span-8 flex flex-grow flex-col my-16 ml-[150px] mr-[50px]">
                    <h1 className="text-4xl text-blue-700 mb-16">What’s the best way for employers to contact you?</h1>
                    <div className="block p-6 rounded-lg shadow-lg bg-white max-w-5xl">
                        <form>
                            <div className="grid grid-cols-2 gap-4">
                                <TextInput label="First Name" onChange={handleChange} id="firstName"
                                           value={newResume.firstName} placeholder="ex: Jhon"/>
                                <TextInput label="Last Name" onChange={handleChange} id="lastName"
                                           value={newResume.lastName} placeholder="ex: Doe"/>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <TextInput label="Profession Name" onChange={handleChange} id="jobTitle"
                                           value={newResume.jobTitle} placeholder="ex: Software engineer"/>
                                <TextInput label="Company Name" onChange={handleChange} id="company"
                                           value={newResume.company} placeholder="ex: Microsoft"/>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <MailInput label="Email Address" onChange={handleChange} id="email"
                                           value={newResume.email} placeholder="ex: jhon.doe@crossteam.io"/>
                                <TextInput label="Phone Number" onChange={handleChange} id="phone"
                                           value={newResume.phone} placeholder="ex: 098765432"/>
                            </div>

                        </form>
                    </div>
                </div>
                <div className="col-span-4 m-16  max-h-[450px] overflow-hidden">
                    <PdfPreview pdf="http://localhost:3000/test.pdf"></PdfPreview>
                </div>
            </div>
        </>
    );
}

export default Heading;