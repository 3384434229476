import PdfPreview from "../../components/pdf.preview"
import CheckBoxInput from "../../components/basic/check.box.input"

function Finalize() {
    return (
        <>
            <div className="grid grid-cols-12">
                <div className="col-span-8 flex flex-grow flex-col my-16 ml-[150px] mr-[50px]">
                    <p className="text-4xl text-blue-700 mb-4">Add additional sections to your resume</p>
                    <form className="mt-5">
                        <div className="grid grid-col-1">
                            <CheckBoxInput id="Accomplishments" label="Accomplishments"></CheckBoxInput>
                            <CheckBoxInput id="Affiliations" label="Affiliations"></CheckBoxInput>
                            <CheckBoxInput id="Additional" label="Additional Information"></CheckBoxInput>
                            <CheckBoxInput id="Software" label="Software"></CheckBoxInput>
                            <CheckBoxInput id="Languages" label="Languages"></CheckBoxInput>
                            <CheckBoxInput id="Certifications" label="Certifications"></CheckBoxInput>
                            <CheckBoxInput id="Interests" label="Interests"></CheckBoxInput>
                        </div>
                    </form>
                </div>
                <div className="col-span-4 m-16 max-h-[450px] overflow-hidden">
                    <PdfPreview pdf="http://localhost:3000/test.pdf"></PdfPreview>
                </div>
            </div>
        </>);
}

export default Finalize;