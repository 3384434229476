import {createContext} from "react";

export const CvContext = createContext();

export const WizardContext = createContext();

export const TemplateContext = createContext();

export const SteepContext = createContext();

export const AuthContext = createContext();
