import {FacebookAuthProvider, signInWithPopup, signOut} from "firebase/auth"

import {call, put, takeLatest} from "redux-saga/effects";

import {signInSuccessful, signOutSuccessful} from "../actions/Auth";

const fbAuthProvider = new FacebookAuthProvider();

function signIn(provider) {
    return signInWithPopup(provider);
}

function* initiateSignIn() {
    try {
        const {user} = yield call(signIn, fbAuthProvider);
        console.log(user);
        yield put(signInSuccessful(user));
    } catch (error) {
        console.log("SIGN IN ERROR: ", error);
    }
}

function* initiateSignOut() {
    try {
        signOut = yield call(signOut);
        yield put(signOutSuccessful);
    } catch (error) {
        console.log("SIGN OUT ERROR: ", error);
    }
}

export function* authWatcherSaga() {
    yield takeLatest("INITIATE_FB_SIGN_IN", initiateSignIn);
    yield takeLatest("INITTIATE_SIGN_OUT", initiateSignOut);
}
