export const cvData = {
    name: "Halit İslam İçli",
    firstName: "A",
    lastName: "B",
    image: "",
    company: "",
    jobTitle: "Frontend Developer",
    location: "Ankara, Turkey",
    email: "imhalid@icloud.com",
    linkedIn: "",
    instagram: "",
    facebook: "",
    twitter: "halidislm",
    github: "imhalid",
    website: "halid.dev",
    about:
        "After graduating from the programming department, I worked remotely on graphic design for about 5 years. I have been working to be a **Front End Developer** for the last 7 months and I am looking for a job in this field. Because of my old job, *I give importance to design and visuality in my work.*",

    toolsAndTechSkills: [
        "Photoshop",
        "Final Cut Pro",
        "Figma",
        "Webflow",
        "Sketch",
        "Illustrator",
    ],
    industryKnowledge: [
        "Javascript",
        "CSS",
        "HTML",
        "Next.js",
        "React",
        "Tailwind",
    ],
    languages: ["Turkish(Native)", "English(A2)"],
    skillTitle1: "Tools and Tech Skills",
    skillTitle2: "Industry Knowledge",
    skillTitle3: "Languages",
    projects: [
        {
            title: "CV Builder",
            link: "https://cv-builder.halid.dev/",
            summary: `With this project you can create real time CV. You can see the changes you have made live. Built with Tailwind and contextAPI.`,
        },
        {
            title: "halid.dev",
            link: "https://halid.dev",
            summary: `My personal website, that I created with Next.js, Tailwind Css, Content Layer.`,
        },

        {
            title: "Weather App",
            link: "https://weather-imhalid.vercel.app/",
            summary: `In this work, a small project where I visualize the data from the API.`,
        },
    ],
    education: [
        {
            title: "Web Design and Programming",
            school: "Anadolu University",
            startDate: "2022",
            endDate: "Now",
        },
        {
            title: "Computer Programming",
            school: "Adnan Menderes University",
            startDate: "2017",
            endDate: "2019",
        },
    ],
    experiences: [
        {
            title: "Graphic Designer, Editor, Data Analysis",
            company: "Suriye Gündemi",
            startDate: "2016",
            endDate: "2020",
            current: true,
            city: "",
            country: "",
            summary: `It is an impartial information sharing platform about Syria. My job task:
       Preparation of up-to-date maps according to the situation in the war zone. 
       Preparing visual infographics about the obtained data and organizing these data. 
       Adding articles and content to the site with Wordpress.`,
        },
        {
            title: "Content Creator, Videographer",
            company: "Zore Aksesuar",
            startDate: "2021",
            endDate: "(2 Months)",
            current: true,
            city: "",
            country: "",
            summary: `In this company that sells accessories for phones and technological products, I prepared the video edits of the new products and mounted them with Final Cut Pro. I shared my social media posts with Facebook Business.`,
        },
    ],
    displayImage: true,
    displayMail: true,
    displayWebSite: true,
    displayLinkedIn: false,
    displayInstagram: false,
    displayFacebook: false,
    displayGithub: true,
    displayTwitter: true,
    activeColor: "#5B21B6",
};

export const emptyCv = {
    summary: "",
    firstName: "",
    lastName: "",
    image: "",
    company: "",
    jobTitle: "",
    location: "",
    email: "",
    phone: "",
    linkedin: "",
    twitter: "",
    github: "",
    website: "",
    about: "",
    skills: [],
    industryKnowledge: [],
    languages: [],
    skillTitle1: "",
    skillTitle2: "",
    skillTitle3: "",
    projects: [
        {
            title: "",
            link: "",
            summary: "",
        },
    ],
    education: [
        {
            title: "",
            study: "",
            degree: "",
            location: "",
            school: "",
            startDate: "",
            endDate: "",
        },
    ],
    experiences: [
        {
            title: "",
            company: "",
            startDate: "",
            endDate: "",
            summary: "",
            city: "",
            country: ""
        },
    ],
    displayImage: false,
    displayMail: false,
    displayWebSite: false,
    displayGithub: false,
    displayTwitter: false,
    activeColor: "#5B21B6",
};