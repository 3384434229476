function SteeperHeader({ steeps }) {
    return (
        <ul className="stepper flex-center" data-mdb-stepper="stepper">
            {steeps.map((step, index) => (
                <li className={step.className + " flex-center"} key={index} data-mdb-stepper="stepper">
                    <div className="stepper-head flex-center">
                        <span className="stepper-head-icon flex-center">{index + 1}</span>
                        <span className="stepper-head-text">{step.title}</span>
                    </div>
                    {/* If you have additional content for each step, it can be included here */}
                    <div className="stepper-content">
                        {/* Content related to each step */}
                    </div>
                </li>
            ))}
        </ul>
    );
}

export default SteeperHeader;
