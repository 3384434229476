import {faCheck, faClone, faDownload, faEdit, faPrint, faRemove} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const Actions = ({resume, resumes, setResumes}) => {
    const navigate = useNavigate();

    const handleEdit = () => {
        navigate("/resume", {state: resume});
    }

    const handleDuplicate = async () => {
        try {
            const response = await axios.post('http://localhost:8085/resume/save', {...resume, id: null});
            setResumes([...resumes, response.data]);
        } catch (error) {
            console.error(error);
        }
    }

    const handleDelete = async () => {
        try {
            await axios.delete('http://localhost:8085/resume/delete', {params: {id: resume.id}});
            const updatedResumes = resumes.filter(r => r.id !== resume.id);
            setResumes(updatedResumes);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="grid grid-cols-6 gap-10 text-center text-gray-500">
            <button className="col-span-1 flex flex-col w-fit cursor-pointer hover:text-orange-300 hover:font-semibold"
                    aria-label="Check Resume">
                Check<FontAwesomeIcon icon={faCheck}/>
            </button>
            <button onClick={handleEdit}
                    className="col-span-1 flex flex-col w-fit cursor-pointer hover:text-orange-300 hover:font-semibold"
                    aria-label="Edit Resume">
                Edit<FontAwesomeIcon icon={faEdit}/>
            </button>
            <button className="col-span-1 flex flex-col w-fit cursor-pointer hover:text-orange-300 hover:font-semibold"
                    aria-label="Print Resume">
                Print<FontAwesomeIcon icon={faPrint}/>
            </button>
            <button className="col-span-1 flex flex-col w-fit cursor-pointer hover:text-orange-300 hover:font-semibold"
                    aria-label="Download Resume">
                Download<FontAwesomeIcon icon={faDownload}/>
            </button>
            <button onClick={handleDuplicate}
                    className="col-span-1 flex flex-col w-fit cursor-pointer hover:text-orange-300 hover:font-semibold"
                    aria-label="Duplicate Resume">
                Duplicate<FontAwesomeIcon icon={faClone}/>
            </button>
            <button onClick={handleDelete}
                    className="col-span-1 flex flex-col w-fit cursor-pointer hover:text-orange-300 hover:font-semibold"
                    aria-label="Remove Resume">
                Remove<FontAwesomeIcon icon={faRemove}/>
            </button>
        </div>
    );
}

export default Actions;
